import * as yup from 'yup';
import {hasSomeOf} from '../../../../utils/permissions';

const teamSchema = yup.object({
  value: yup.string(),
  label: yup.string(),
  isFixed: yup.boolean(),
  accessAllTopics: yup.boolean(),
});

export const getSchema = () => {
  const canUpdateAllTeams = hasSomeOf(['teams.update_all']);

  return yup.object({
    name: yup
      .string()
      .required('Enter Topic name')
      .max(255, 'Topic name must be less than 255 characters')
      .matches(
        /^[^&?,]*$/,
        'Topic name cannot contain any of the following characters: & ? ,'
      ),
    description: yup.string().optional(),
    domains: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .url('Must be a valid URL (https://www.example.com)')
          .optional(),
        paths: yup.array().of(
          yup.object().shape({
            value: yup
              .string()
              .test('valid-path', 'Must be a valid path (/example)', (path) => {
                if (!path) return true; // Allow empty strings
                return !(path === '/' || !path.startsWith('/') || path.includes(' '));
              })
              .optional(),
          })
        ),
      })
    ),
    teams: canUpdateAllTeams
      ? yup.array().of(teamSchema).optional()
      : yup.array().of(teamSchema).min(1, 'Select at least one Team').required(),
  });
};
