import React, {useEffect, useRef, useState} from 'react';
import TimeMe from 'timeme.js';
import {useIdleTimer} from 'react-idle-timer';
import {track} from '../utils/analytics';
import {useSessionExpiry} from '../customHooks/useSessionExpiry';
import {useWindowFocus} from '../customHooks/useWindowFocus';
import {
  ICreateShareableContentActivity,
  ICreateShareableContentEventRequest,
  IPublicContent,
} from 'spekit-types';
import {PDFViewer} from 'spekit-ui';
import {ReadOnlyView} from 'spekit-shared-components/src/RichTextEditor/components';
import {logging, shareLinks} from 'spekit-datalayer';
interface IContentTimeTrackerProps {
  content: IPublicContent;
  email?: string;
}
const IDLE_TIMEOUT_DURATION = 2 * 60 * 1000; // 2 minutes

const ContentTimeTracker = ({content, email}: IContentTimeTrackerProps) => {
  const [runTimer, setRunTimer] = useState(false);
  const [clickEventCreated, setClickEventCreated] = useState(false);
  const isIdleRef = useRef(false);
  const hasFocus = useRef(true);

  const sessionId = useSessionExpiry();
  useWindowFocus({hasFocusRef: hasFocus});

  const {url, definition, id, type} = content;
  const onIdle = () => {
    isIdleRef.current = true;
    TimeMe.stopTimer();
  };

  const onActive = () => {
    isIdleRef.current = false;
    TimeMe.startTimer();
  };

  const {start} = useIdleTimer({
    onIdle,
    onActive,
    timeout: IDLE_TIMEOUT_DURATION,
    throttle: 500,
    startManually: true,
  });

  useEffect(() => {
    if (!!sessionId && !clickEventCreated) {
      const requestPayload: ICreateShareableContentEventRequest = {
        shareable_content_id: content.id,
        event: 'click',
        data: {session_id: sessionId, email: email},
      };

      shareLinks.createShareableContentEvent(requestPayload);

      setClickEventCreated(true);
    }
  }, [clickEventCreated, content.id, email, sessionId]);

  useEffect(() => {
    if (type !== 'asset' || (type === 'asset' && runTimer)) {
      TimeMe.initialize({
        currentPageName: 'External View Page',
      });
      setRunTimer(true); // In case of spek
    }
  }, [type, runTimer]);

  useEffect(() => {
    // Reset timer and send activity data when content changes
    const logTime = async (event: string) => {
      const timeSpentInSeconds = TimeMe.getTimeOnCurrentPageInSeconds();
      if (timeSpentInSeconds) {
        track('External View Time Tracking', {
          source: 'Webapp',
          time_spent: timeSpentInSeconds,
          email: email,
          shareable_content_id: id,
          session_id: sessionId,
          event_type: event,
        });

        const requestPayload: ICreateShareableContentActivity = {
          shareable_content_id: id,
          time_spent_in_seconds: timeSpentInSeconds,
          session_id: sessionId,
          email: email,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        try {
          await shareLinks.createShareableContentActivity(requestPayload);
        } catch (e) {
          logging.capture(e);
        }
      }
      TimeMe.resetRecordedPageTime('External View Page');
    };

    // Send activity data for content change
    logTime('On Content Change');
  }, [id, email, sessionId]);

  useEffect(() => {
    const updateTimer = () => {
      TimeMe.resetRecordedPageTime('External View Page');
      const focused = document.hasFocus();
      if (!isIdleRef.current && hasFocus.current && runTimer && focused) {
        TimeMe.startTimer();
      }
    };

    const logTime = async (event: string) => {
      const timeSpentInSeconds = TimeMe.getTimeOnCurrentPageInSeconds();
      if (timeSpentInSeconds) {
        track('External View Time Tracking', {
          source: 'Webapp',
          time_spent: timeSpentInSeconds,
          email: email,
          shareable_content_id: id,
          session_id: sessionId,
          event_type: event,
        });

        const requestPayload: ICreateShareableContentActivity = {
          shareable_content_id: id,
          time_spent_in_seconds: timeSpentInSeconds,
          session_id: sessionId,
          email: email,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        try {
          await shareLinks.createShareableContentActivity(requestPayload);
        } catch (e) {
          logging.capture(e);
        }
      }
      updateTimer(); // Resetting timer after sending data to segment
    };

    const intervalId = setInterval(() => {
      logTime('On Interval');
    }, 30000); // 30 seconds

    const handleVisibilityChange = () => {
      if (document.visibilityState !== 'visible') {
        // stop timer and send data to segment on page change
        TimeMe.stopTimer();
        logTime('On Page Change');
      }
    };

    const handleBeforeUnload = () => {
      // Capture time spent when the user is leaving the page
      TimeMe.stopTimer();
      logTime('On Page Close');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      clearInterval(intervalId);
    };
  }, [id, sessionId, runTimer, email]);

  const handleWebViewerFocus = () => {
    hasFocus.current = true;
    isIdleRef.current = false;
    TimeMe.startTimer();
    start();
  };
  const handleWebViewerLoad = () => {
    setRunTimer(true);
  };

  function renderChildren() {
    if (type === 'asset') {
      return (
        <PDFViewer
          contentId={id}
          url={url || content.definition!}
          isExternalView={true}
          handleWebViewerFocus={handleWebViewerFocus}
          handleWebViewerLoad={handleWebViewerLoad}
        />
      );
    } else {
      return <ReadOnlyView value={definition!} />;
    }
  }

  return <>{renderChildren()}</>;
};

export default ContentTimeTracker;
