import {IFlagState, IPermissions} from 'spekit-types';
import {adminToolsPermissions, planPermissions, teamsPermissions} from './useSettings';

const homePages = ['/app/dashboard'];
const settingsPages = ['/app/settings', '/app/accounts'];
const analyticPages = ['/app/analytics'];
const searchPages = ['/app/search'];

const getSettingsNavItems = (permissions: IPermissions, flags: IFlagState) => [
  {
    name: 'Teams & Users',
    route: '/app/settings/access',
    permission: permissions.hasSomeOf(teamsPermissions),
  },
  {
    name: 'Admin Tools',
    route: '/app/settings/customize/columns',
    permission: permissions.hasSomeOf(adminToolsPermissions),
  },
  {
    name: 'Plan',
    route: '/app/settings/plan',
    permission: permissions.hasSomeOf(planPermissions),
  },
  {
    name: 'Connect',
    route: '/app/accounts',
    permission: permissions.hasSomeOf(['salesforceobjects.sync_own']),
  },
  {
    name: 'API Tokens',
    testId: 'sidenav-item-api-tokens',
    route: '/app/settings/api-tokens',
    permission: permissions.hasSomeOf(adminToolsPermissions),
  },
];

const getDefaultNavItems = (permissions: IPermissions, flags: IFlagState) => [
  {
    name: 'Topics',
    route: '/app/wiki/topics',
    permission: true,
    testId: 'sidenav-item-topics',
  },

  {
    name: 'Content',
    route: '/app/wiki/',
    permission: true,
    testId: 'sidenav-item-content',
  },
  {
    name: 'Flows',
    testId: 'sidenav-item-flows',
    route: '/app/flows',
    permission:
      permissions.hasSomeOf(['flows.view_flows', 'flows.read_all', 'flows.read_own']) &&
      flags.hasFlowFlag,
  },
  {
    name: 'Spotlights',
    testId: 'sidenav-item-spotlights',
    route: '/app/spotlights',
    permission: permissions.hasSomeOf(['spotlights.add_own']) && flags.hasSpotlightFlag,
  },
  {
    name: 'Knowledge Checks',
    testId: 'sidenav-item-knowledge-checks',
    route: '/app/knowledge-checks',
    permission: permissions.hasSomeOf(['quiz.add']) && flags.hasQuizFlag,
  },
  {
    name: 'Templates',
    testId: 'sidenav-item-templates',
    route: '/app/wiki/onboarding',
    permission: permissions.hasSomeOf(['obtopics.read_all', 'obtopics.import_all']),
  },
];

const getHomeNavItems = (flags: IFlagState) => [
  {
    name: 'Getting started',
    route: '/app/dashboard/getting-started',
    permission: true,
    testId: 'sidenav-item-getting-started-dashboard',
  },
  {
    name: 'My overview',
    route: '/app/dashboard/my-overview',
    permission: true,
    testId: 'sidenav-item-overview-dashboard',
  },
];

const getAnalyticNavItems = (flags: IFlagState) => [
  {
    name: 'AI Analytics (New)',
    route: '/app/analytics/ai-analytics-dashboard',
    permission: flags.hasAIIndexContentIntoPineconeFlag && flags.hasAIAIAssistFlag,
    testId: 'sidenav-item-ai-analytics-dashboard',
  },
  {
    name: 'Activation',
    route: '/app/analytics/activation-dashboard',
    permission: true,
    testId: 'sidenav-item-activation-dashboard',
  },
  {
    name: 'Adoption',
    route: '/app/analytics/adoption-dashboard',
    permission: true,
    testId: 'sidenav-item-adoption-dashboard',
  },
  {
    name: 'Governance',
    route: '/app/analytics/governance-dashboard',
    permission: true,
    testId: 'sidenav-item-governance-dashboard',
  },
  {
    name: 'Content Creation',
    route: '/app/analytics/content-creation-dashboard',
    permission: true,
    testId: 'sidenav-item-content-creation-dashboard',
  },
  {
    name: 'Flows',
    route: '/app/analytics/flows-dashboard',
    permission: flags.hasFlowFlag,
    testId: 'sidenav-item-flows-dashboard',
  },
  {
    name: 'Knowledge Checks',
    route: '/app/analytics/knowledge-checks-dashboard',
    permission: flags.hasQuizFlag,
    testId: 'sidenav-item-knowledge-checks-dashboard',
  },
  {
    name: 'SmartSend Links',
    route: '/app/analytics/share-link-activity',
    permission: flags.hasSpekLinkGenerationFlag,
    testId: 'sidenav-item-share-links-dashboard',
  },
  {
    name: 'Deal Rooms',
    route: '/app/analytics/deal-room-analytics',
    permission: flags.hasDealRoomFlag,
    testId: 'sidenav-item-deal-room-analytics',
  },
  {
    name: 'Spotlights',
    route: '/app/analytics/spotlights-dashboard',
    permission: true,
    testId: 'sidenav-item-spotlights-dashboard',
  },
  {
    name: 'Custom Reports',
    route: '/app/analytics/custom-reports',
    permission: flags.hasCustomReportsDashboardFlag,
    testId: 'sidenav-item-custom-reports-dashboard',
  },
  {
    name: 'Buyer Engagement',
    route: '/app/analytics/smartsend-dashboard',
    permission: flags.hasSpekLinkGenerationFlag,
  },
];

const getViewerAnalyticNavItems = (flags: IFlagState) => [
  {
    name: 'SmartSend Links',
    route: '/app/analytics/share-link-activity',
    permission: flags.hasSpekLinkGenerationFlag,
    testId: 'sidenav-item-share-links-dashboard',
  },
  {
    name: 'Deal Rooms',
    route: '/app/analytics/deal-room-analytics',
    permission: flags.hasDealRoomFlag,
    testId: 'sidenav-item-deal-room-analytics',
  },
];

export const getNavItems = (
  permissions: IPermissions,
  flags: IFlagState,
  path: string[],
  role: string = 'Viewer'
) => {
  const isSettingRoute = settingsPages.some((page) => path.includes(page));
  const isAnalyticPage = analyticPages.some((page) => path.includes(page));
  const isSearchPage = searchPages.some((page) => path.includes(page));
  const isHomePage = homePages.some((page) => path.includes(page));

  // For Settings and Connections pages
  if (isSettingRoute) {
    return getSettingsNavItems(permissions, flags);
  }

  if (isHomePage) {
    return getHomeNavItems(flags);
  }

  if (isAnalyticPage) {
    return role === 'Viewer'
      ? getViewerAnalyticNavItems(flags)
      : getAnalyticNavItems(flags);
  }

  if (isSearchPage) return [];

  //For all of the rest pages
  return getDefaultNavItems(permissions, flags);
};
