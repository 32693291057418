import {Component} from 'react';
import {Provider, connect} from 'react-redux';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import myhistory from './history';
import store from './reduxStore';
import './styles/fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Layout from './layout';
import PublicLayout from './layoutPublic';
import MagicLinkLogin from './MagicLogin/MagicLinkLogin';
import SettingsNav from './components/SettingsNav';
import AppCompatibilityStatus from './appCompatibilityStatus/appCompatibilityStatus';
import AddCommentsException from './appCompatibilityStatus/addCommentsException';
import AnalyticsWrapper from './AnalyticsWrapper';
import ErrorBoundary from './ErrorBoundary';
import {detectThirdPartyBlocks} from './utils/blockDetect';
import {notifications} from 'spekit-ui';
import {
  IntegrationsContextProvider,
  QueryClientProvider,
  AnalyticsProvider,
} from 'spekit-shared-components';
import {addNotification, removeNotification, showPaywall} from './redux/actions';
import {
  fetchMiddleWare,
  AppPermission,
  Track,
  logging,
  contentIntegrations,
} from 'spekit-datalayer';
import WikiRoutes from './features/Wiki/routes';
import IntegrationsRoute from './features/Integrations/routes';
import KnowledgeCheckRoutes from './features/KnowledgeChecks/routes';
import ErrorPage from './connectionsPage/Integrations/SSO/ErrorPage/ErrorPage';
import LicenseExceeded from './pages/LicenseExceeded/LicenseExceeded';
import ExtensionNotInstalled from './pages/ExtensionNotInstalled/ExtensionNotInstalled';
import AnalyticsRoutes from './features/Analytics/routes';
import DealRoomRoute from './features/DealRoom/routes';
import HomeRoutes from './dashboardRoutes';
import SSOPage from './features/Login/pages/SSOPage/SSOPage';
import LoginPage from './features/Login/pages/LoginPage/LoginPage';
import OTPPage from './features/Login/pages/OTPPage/OTPPage';
import LoginAllOptions from './features/Login/pages/LoginAllOptions/LoginAllOptions';
import ResetPasswordPage from './features/Login/pages/ResetPasswordPage/ResetPasswordPage';
import ResetPasswordConfirmationPage from './features/Login/pages/ResetPasswordConfirmationPage/ResetPasswordConfirmationPage';
import AcceptInvite from './features/Login/pages/AcceptInvite/AcceptInvite';
import RetrieveUsername from './features/Login/pages/RetrieveUsername/RetrieveUsername';
import PasswordExpiredLink from './features/Login/pages/PasswordExpiredLink/PasswordExpiredLink';
import FlowPreviewerPage from './features/Flows/pages/Previewer';
import FlowViewerPage from './features/Flows/pages/Viewer';
import SearchRoutes from './features/Search/routes';
import NotFound from './pages/NotFound';
import PublicContent from './PublicContent';
import Profile from './pages/Profile';
import ProfileSettingsEdit from './pages/Profile/ProfileSettingsEdit';
import {AdminTools} from './features/Settings/pages/AdminTools';
import {IntegrationAppProvider} from '@integration-app/react';
import {CustomThemeProvider} from './components/CustomThemeProvider';
import GenerateAPITokens from './features/APITokens/GenerateAPIToken';

import ApryseEmbedViewer from './ApryseEmbed/ApryseEmbed';
import {initAPI} from './api';
import {track} from './utils/analytics';

AppPermission.permissionMiddleWare(store);

const {onNotify, Notifications, notify} = notifications;
const {fetchMiddleWareInjector} = fetchMiddleWare;
fetchMiddleWareInjector(store, showPaywall);

initAPI();

const NotificationView = connect(
  (state, props) => {
    return {...state.notifications};
  },
  (dispatch) => {
    return {
      removeNotification: (notification) => {
        if (removeNotification !== null) dispatch(removeNotification(notification));
      },
      addNotification: (notification) => {
        if (addNotification !== null) dispatch(addNotification(notification));
      },
    };
  }
)(Notifications);

onNotify(
  {
    addNotification,
    removeNotification,
  },
  store,
  null
);

Track.init(store);

detectThirdPartyBlocks();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {featureFlags: []};
    this.pages = {};
    this.requestedPages = {};
    this.loader = this.loader.bind(this);
    this.fetchToken = this.fetchToken.bind(this);
  }

  loader(componentModule) {
    const self = this;
    if (self.pages[componentModule]) {
      return self.pages[componentModule];
    }
    if (!self.requestedPages[componentModule]) {
      self.requestedPages[componentModule] = true;
      import('./' + componentModule)
        .then((Page) => {
          // eslint-disable-next-line
          self.pages[componentModule] = <Page.default />;
          self.forceUpdate();
        })
        .catch((err) => {
          //debugger;
          notify({
            error: true,
            text: 'An unexpected error has occured. Please try again.',
          });
          logging.capture(err);
        });
    }
    return <div />;
  }

  async fetchToken() {
    const {token} = await contentIntegrations.generateToken();
    return token;
  }

  render() {
    return (
      <QueryClientProvider>
        <Provider store={store}>
          <CustomThemeProvider>
            <div>
              <NotificationView />
              <AnalyticsProvider track={track}>
                <Router history={myhistory}>
                  <ErrorBoundary>
                    <AnalyticsWrapper>
                      <Switch>
                        <Route path='/app' exact>
                          {(props) => {
                            if (props.location.search === '?chrome') {
                              return <Redirect to='/app/wiki/topics?chrome' />;
                            }
                            return <Redirect to='/app/wiki/topics' />;
                          }}
                        </Route>
                        <Route
                          path={[
                            '/app/public/:contentType/:contentId',
                            '/app/share/:contentType/:contentId',
                          ]}
                          exact
                          component={PublicContent}
                        />
                        <Route
                          path='/app/chrome/tutorial/:step'
                          exact
                          component={this.loader.bind(this, 'chromeTutorial')}
                        />
                        <Route path='/app'>
                          <IntegrationsContextProvider>
                            <IntegrationAppProvider fetchToken={this.fetchToken}>
                              <Layout>
                                <Switch>
                                  <Route
                                    path='/app/interstitial'
                                    exact
                                    component={this.loader.bind(this, 'interstitial')}
                                  />
                                  <Route path='/app/integrations'>
                                    <IntegrationsRoute />
                                  </Route>
                                  <Route path='/app/wiki'>
                                    <WikiRoutes />
                                  </Route>
                                  <Route path='/app/knowledge-checks'>
                                    <KnowledgeCheckRoutes />
                                  </Route>
                                  <Route path='/app/deal-room'>
                                    <DealRoomRoute />
                                  </Route>
                                  <Route path='/app/search'>
                                    <SearchRoutes />
                                  </Route>
                                  <Route path='/app/error/extension-not-installed'>
                                    <ExtensionNotInstalled />
                                  </Route>
                                  <Route path='/app/analytics'>
                                    <AnalyticsRoutes />
                                  </Route>
                                  <Route path='/app/dashboard'>
                                    <HomeRoutes />
                                  </Route>
                                  <Route
                                    path='/app/connectSF'
                                    exact
                                    component={this.loader.bind(this, 'connectSF')}
                                  />
                                  <Route
                                    path='/app/connectSF/complete'
                                    exact
                                    component={this.loader.bind(this, 'completeSF')}
                                  />
                                  <Route
                                    path='/app/reconnectSF/complete'
                                    exact
                                    component={this.loader.bind(this, 'completeSF')}
                                  />
                                  <Route
                                    path='/app/authSF/complete'
                                    exact
                                    component={this.loader.bind(this, 'completeSF')}
                                  />
                                  <Route
                                    path='/app/inviteSF/complete'
                                    exact
                                    component={this.loader.bind(this, 'completeSF')}
                                  />
                                  <Route
                                    path='/app/connectSF/:error'
                                    exact
                                    component={this.loader.bind(this, 'connectSF')}
                                  />
                                  <Route
                                    path='/app/chrome'
                                    exact
                                    component={this.loader.bind(this, 'chrome')}
                                  />
                                  <Route
                                    path='/app/spotlights'
                                    exact
                                    component={this.loader.bind(
                                      this,
                                      'spotlightsListPage'
                                    )}
                                  />
                                  <Route
                                    path='/app/flows/:flowId?'
                                    exact
                                    component={this.loader.bind(this, 'flowsListPage')}
                                  />
                                  <Route
                                    path='/app/flows/trigger/:flowId'
                                    exact
                                    component={this.loader.bind(this, 'triggerFlow')}
                                  />
                                  <Route path='/app/flows/preview/:flowId' exact>
                                    <FlowPreviewerPage />
                                  </Route>
                                  <Route path='/app/flows/view/:flowId' exact>
                                    <FlowViewerPage />
                                  </Route>
                                  <Route
                                    path='/app/accounts'
                                    exact
                                    component={this.loader.bind(this, 'connectionsPage')}
                                  />
                                  <Route
                                    path='/app/accounts/manageobjects'
                                    exact
                                    component={this.loader.bind(this, 'objectSettings')}
                                  />
                                  <Route
                                    path='/app/notifications'
                                    exact
                                    component={this.loader.bind(this, 'notificationList')}
                                  />
                                  <Route
                                    path='/app/generate'
                                    exact
                                    component={this.loader.bind(this, 'generateToken')}
                                  />
                                  <Route path='/app/settings'>
                                    <SettingsNav>
                                      <Switch>
                                        <Route
                                          path='/app/settings/profile'
                                          exact
                                          component={Profile}
                                        />
                                        <Route
                                          path='/app/settings/profile/edit'
                                          exact
                                          component={ProfileSettingsEdit}
                                        />
                                        <Route
                                          path='/app/settings/customize/columns'
                                          exact
                                          component={AdminTools}
                                        />
                                        <Route
                                          path='/app/settings/plan'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'planSettings'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/customize/bulkUpload'
                                          exact
                                          component={AdminTools}
                                        />
                                        <Route
                                          path='/app/settings/access'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'accessSettingsPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/teams'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'teamsListPage/TeamsListPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/teams/:teamId/users'
                                          exact
                                          component={this.loader.bind(this, 'usersPage')}
                                        />
                                        <Route
                                          path='/app/settings/access/teams/:teamId'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'teamFormPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/invite/sf'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'inviteSfPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/invite/email'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'inviteEmailPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/invite/upload'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'inviteFileUploadPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/upload/users-to-team'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'addUsersToTeamPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/invite/pending'
                                          exact
                                          component={this.loader.bind(
                                            this,
                                            'invitePendingPage'
                                          )}
                                        />
                                        <Route
                                          path='/app/settings/access/users'
                                          exact
                                          component={this.loader.bind(this, 'usersPage')}
                                        />
                                        <Route
                                          path='/app/settings/api-tokens'
                                          exact
                                          component={GenerateAPITokens}
                                        />
                                        <Redirect
                                          path='/app/settings/customize'
                                          exact
                                          to='/app/settings/customize/columns'
                                        />
                                        <Redirect
                                          path='/app/settings'
                                          exact
                                          to={'/app/settings/access'}
                                        />
                                        <Route>
                                          <Redirect to='/notfound' replace />
                                        </Route>
                                      </Switch>
                                    </SettingsNav>
                                  </Route>
                                  <Route path='/app/ssoError' exact>
                                    <ErrorPage />
                                  </Route>
                                  <Route>
                                    <Redirect to='/notfound' replace />
                                  </Route>
                                </Switch>
                              </Layout>
                            </IntegrationAppProvider>
                          </IntegrationsContextProvider>
                        </Route>
                        <Route
                          path='/kb/signup'
                          component={this.loader.bind(this, 'knowledgeBaseSignup')}
                        />
                        <Route
                          path='/appscompat-status'
                          exact
                          component={AppCompatibilityStatus}
                        />
                        <Route
                          path='/appscompat-add'
                          exact
                          component={AddCommentsException}
                        />
                        <Route path='/login-beta' exact component={LoginPage} />
                        <Route
                          path='/login-all-options'
                          exact
                          component={LoginAllOptions}
                        />
                        <Route
                          path='/retrieve-user-beta'
                          exact
                          component={RetrieveUsername}
                        />
                        <Route path='/verifypin-beta' exact component={OTPPage} />
                        <Route
                          path='/handleSproutVideo'
                          exact
                          component={this.loader.bind(this, 'sproutVideoViewer')}
                        />
                        <Route path='/'>
                          <Switch>
                            <Route path='/loadApryseViewer' exact>
                              <ApryseEmbedViewer />
                            </Route>
                            <Route path='/login' exact component={LoginPage} />
                            <Route
                              path='/login-all-options'
                              exact
                              component={LoginAllOptions}
                            />
                            <Route path='/notfound' exact component={NotFound} />
                            <Route path='/verifypin' exact component={OTPPage} />
                            <Route path='/sso-login' exact component={SSOPage} />
                            <Route
                              path='/retrieve-user'
                              exact
                              component={RetrieveUsername}
                            />
                            <Route
                              path='/reset-password'
                              exact
                              component={ResetPasswordPage}
                            />
                            <Route
                              path='/reset-password-error'
                              exact
                              component={PasswordExpiredLink}
                            />
                            <Route
                              path='/passwordreset/:token?'
                              exact
                              component={ResetPasswordConfirmationPage}
                            />
                            <Route path='/loginSF/:error' exact component={LoginPage} />
                            <Route
                              path='/acceptinvite/:inviteType/:id'
                              component={AcceptInvite}
                            />
                            <Route path='/' exact>
                              {(props) => {
                                if (props.location.search === '?chrome') {
                                  return <Redirect to='/app?chrome' />;
                                }
                                return <Redirect to='/app' />;
                              }}
                            </Route>
                            <Route path='/notallowed' exact component={NotFound} />
                            <PublicLayout>
                              <Switch>
                                <Route
                                  path='/licensesExceeded'
                                  exact
                                  component={LicenseExceeded}
                                />
                                <Route
                                  path='/login/magic'
                                  exact
                                  component={MagicLinkLogin}
                                />
                                <Route
                                  path='/logout'
                                  exact
                                  component={this.loader.bind(this, 'logout')}
                                />
                                <Route>
                                  <Redirect to='/notfound' replace />
                                </Route>
                              </Switch>
                            </PublicLayout>
                          </Switch>
                        </Route>
                      </Switch>
                    </AnalyticsWrapper>
                  </ErrorBoundary>
                </Router>
              </AnalyticsProvider>
            </div>
          </CustomThemeProvider>
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default App;
