import React from 'react';
import {SkeletonText as ChakraSkeletonText, SkeletonTextProps} from '@chakra-ui/react';

export type TSkeletonTextProps = SkeletonTextProps;

export const SkeletonText = (props: TSkeletonTextProps) => (
  <ChakraSkeletonText
    startColor='neutral.50'
    endColor='neutral.100'
    speed={1.0}
    {...props}
  />
);
