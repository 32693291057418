import React, {useState} from 'react';
import {
  DSButton as Button,
  DSModal as Modal,
  FormControl,
  FormErrorMessage,
  Text,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Link,
} from 'spekit-ui';

interface IEmailAddressModal {
  isOpen: boolean;
  onClose: () => void;
  onViewContent: (emailAddress: string) => void;
}

export const EmailAddressModal = ({
  isOpen,
  onClose,
  onViewContent,
}: IEmailAddressModal) => {
  const [emailAddressError, setEmailAddressError] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const initialRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddressError('');
    setEmailAddress(e.target.value);
  };

  const isEmailValid = (email: string) => {
    return !!email.match(/.+@.+\..+/);
  };
  const checkFieldValidations = () => {
    if (!isEmailValid(emailAddress)) {
      setEmailAddressError(
        'A valid email address is required to access to this content.'
      );
      return false;
    }
    return true;
  };

  const handleViewContent = () => {
    if (!checkFieldValidations()) {
      return;
    }
    setEmailAddressError('');

    onViewContent(emailAddress);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='sm'
      overlayProps={{backdropFilter: 'blur(5px)'}}
      closeOnEsc={false}
      initialFocusRef={initialRef}
    >
      <ModalHeader mb={0}>The sender requests your email address to continue</ModalHeader>

      <ModalBody overflow='visible'>
        <FormControl isRequired isInvalid={!!emailAddressError}>
          <FormLabel>Work email address</FormLabel>
          <Input
            name='email_address'
            value={emailAddress}
            onChange={handleEmailAddressChange}
            placeholder='Enter your work email address'
            isInvalid={!!emailAddressError}
            isRequired
            data-testid='email-address-input'
            ref={initialRef}
          />
          <FormErrorMessage>{emailAddressError}</FormErrorMessage>
        </FormControl>
      </ModalBody>

      <ModalFooter mb={16}>
        <Button
          size='medium'
          variant='contained'
          colorScheme='primary'
          onClick={handleViewContent}
          data-testid='view-content-btn'
        >
          View content
        </Button>
      </ModalFooter>

      <Text fontSize='tiny'>
        By proceeding, you agree to our{' '}
        <Link target='_blank' href='https://spekit.com/legal/privacy-policy/'>
          Privacy Policy.
        </Link>
      </Text>
    </Modal>
  );
};
