import {handleErrors} from './handleErrors';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';

export const retrieve = async <T>(
  API: string,
  contentType?: string,
  shouldIgnoreCache?: boolean,
  signal?: AbortController['signal']
): Promise<T> => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': contentType || 'application/json',
  };
  if (shouldIgnoreCache) {
    headers['Cache-Control'] = 'no-cache';
  }

  let response = await fetch(API, {
    credentials: 'include',
    method: 'GET',
    ...(contentType && {redirect: 'manual'}),
    headers,
    signal,
  });
  if (contentType) {
    return handleErrors(response, response);
  }
  return handleErrors(response, await response.json());
};

export const destroy = async <T>(API: string, parseJson = true): Promise<T> => {
  let response = await fetch(API, {
    credentials: 'include',
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return handleErrors(response, parseJson ? await response.json() : response);
};

export const update = async <T>(
  API: string,
  body: object,
  signal?: AbortController['signal']
): Promise<T> => {
  let response = await fetch(API, {
    signal,
    credentials: 'include',
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return handleErrors(response, await response.json());
};

export const create = async <T, U>(
  API: string,
  body?: T,
  customHeaders?: object
): Promise<U> => {
  let response = await fetch(API, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...customHeaders,
    },
    ...(body && {body: JSON.stringify(body)}),
  });

  // nginx 413 response has no body, so it should be checked before response.json()
  if (response.status === 413) throw new Error('Payload too large');

  return handleErrors(response, body ? await response.json() : response) as U;
};

export const modify = async <T, U>(API: string, body: T): Promise<U> => {
  let response = await fetch(API, {
    credentials: 'include',
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return handleErrors(response, await response.json());
};

export const createForm = async <T>(API: string, formData: FormData): Promise<T> => {
  let response = await fetch(API, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });

  return handleErrors(response, await response.json());
};

export const patchForm = async <T>(API: string, formData: FormData): Promise<T> => {
  let response = await fetch(API, {
    credentials: 'include',
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });

  return handleErrors(response, await response.json());
};
