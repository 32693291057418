import React, {useRef, useEffect, useState} from 'react';
import {Box} from '../Layout/Box';
import {logging} from 'spekit-datalayer';
export interface IPDFViewer {
  url: string;
  contentId?: string;
  size?: 'small' | 'large' | 'default';
  handleWebViewerFocus?: () => void;
  handleWebViewerLoad?: () => void;
  isExternalView?: boolean;
}
const sizes = {
  large: {
    h: '900px',
  },
  small: {
    h: '400px',
  },
  default: {
    h: '100%',
  },
};
const documentViewerEvents = [
  'keyDown',
  'keyUp',
  'click',
  'mouseEnter',
  'mouseRightUp',
  'mouseRightDown',
  'pageNumberUpdated',
];
export const PDFViewer = ({
  url,
  contentId,
  size = 'default',
  handleWebViewerFocus,
  handleWebViewerLoad,
  isExternalView,
}: IPDFViewer) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const instanceRef = useRef<any>(null);
  const [viewerId] = useState(() => `pdf-viewer-${contentId}`);
  const cleanupInstance = async () => {
    try {
      if (instanceRef.current?.Core?.documentViewer) {
        await instanceRef.current.Core.documentViewer.closeDocument();
        instanceRef.current.Core.documentViewer = null;
      }
      instanceRef.current = null;
      // Remove the viewer element if it exists
      const existingViewer = document.getElementById(viewerId);
      if (existingViewer) {
        existingViewer.remove();
      }
    } catch (error) {
      logging.capture(error);
    }
  };
  useEffect(() => {
    let mounted = true;
    const initializeWebViewer = async () => {
      try {
        // Dynamically import WebViewer
        // Prevents WebViewer from being bundled and executed in content scripts - where custom elements are not supported
        const {default: WebViewer} = await import(
          /* webpackChunkName: "pdftron-webviewer" */ '@pdftron/webviewer'
        );
        // Clean up previous instance if it exists
        await cleanupInstance();
        if (!mounted) return;
        // Create a new div element for the viewer
        const viewerElement = document.createElement('div');
        viewerElement.id = viewerId;
        viewerElement.style.width = '100%';
        viewerElement.style.height = '100%';
        // Add the viewer element to the container
        if (containerRef.current) {
          containerRef.current.appendChild(viewerElement);
        }
        instanceRef.current = WebViewer.Iframe(
          {
            path: '/',
            licenseKey: process.env.REACT_APP_PDFJS_LICENSE_KEY,
            ui: 'legacy',
            initialDoc: url,
            isReadOnly: true,
            disableObjectURLBlobs: true,
            disabledElements: [
              'downloadButton',
              'saveAsButton',
              'ribbons', // Annotations ribbon
              'toolsHeader', // Extra Annotation tools
              'annotationPopup', // Annotation popup
              'textPopup', // Text selection popup
              'toggleNotesButton', // Comments menu / section
              'contextMenuPopup', // Context menu popup
              'menuButton', // Menu Button
              'menuOverlay', // Menu Overlay
              'thumbnailControl', // Panel Controls
              'pageManipulationOverlayButton', // Page manipulation overlay button
              'pageManipulationOverlay', // Page manipulation overlay
            ],
          },
          viewerElement
        ).then((instance: any) => {
          if (!mounted) return null;
          const {documentViewer} = instance.Core;
          const fitMode = instance.UI.FitMode;
          instance.UI.setFitMode(fitMode.FitPage);
          // Set custom font URL if it's provided
          // This is used to load fonts from a custom URL
          // ref: https://docs.apryse.com/web/faq/self-serve-substitute-fonts
          if (process.env.REACT_APP_PDFJS_CUSTOM_FONT_URL) {
            instance.Core.setCustomFontURL(process.env.REACT_APP_PDFJS_CUSTOM_FONT_URL);
          }
          documentViewer.addEventListener('documentLoaded', () => {
            if (isExternalView && mounted) {
              if (handleWebViewerLoad) handleWebViewerLoad();
              const {documentViewer} = instance.Core;
              documentViewerEvents.forEach((event) => {
                documentViewer.addEventListener(event, handleWebViewerFocus);
              });
            }
          });
          return instance;
        });
      } catch (e) {
        logging.capture(e);
        if (mounted) {
          await cleanupInstance();
        }
      }
    };
    initializeWebViewer();
    // Cleanup function
    return () => {
      mounted = false;
      cleanupInstance();
    };
  }, [url, handleWebViewerFocus, handleWebViewerLoad, isExternalView, viewerId]);
  return (
    <Box
      ref={containerRef}
      {...sizes[size]}
      w='100%'
      flexShrink={0}
      data-testid='pdf-viewer-container'
      position='relative'
      border='1px solid'
      borderColor='neutral.200'
    />
  );
};
