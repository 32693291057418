import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {searchUtils, utils} from 'spekit-datalayer';
import Suggestions from 'spekit-shared-components/src/Search/Suggestions';
import {SearchBar} from 'spekit-ui';

export const AppSearch = () => {
  const location = useLocation();
  const history = useHistory();
  const queryFilter = utils.parseQs(location.search);
  const [searchTerm, setSearchTerm] = useState((queryFilter.query as string) || '');

  const handleOnChangeForSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(e.target.value);

  const handleNewSearch = () => {
    const searchParams = searchUtils.buildSearchQuery({search: searchTerm});
    history.push(`/app/search?${searchParams}`);
  };
  const handleClearNewSearch = () => setSearchTerm('');

  return (
    <SearchBar
      value={searchTerm}
      onChange={handleOnChangeForSearch}
      onSearch={handleNewSearch}
      onClear={handleClearNewSearch}
      testId='webapp-universal-search-bar'
      suggestions={<Suggestions />}
      placeholder='Search content (ie: sales playbook)'
    />
  );
};
