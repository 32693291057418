import {Box, Flex, List, ListItem, Text, ListIcon, ICONS} from 'spekit-ui';
import {RiFileTextLine} from 'react-icons/ri';
import {IPublicBundle} from 'spekit-types';
import {getAssetExtension} from 'spekit-datalayer';
import {useEffect} from 'react';

interface BundleSidebarProps {
  bundle: IPublicBundle;
  currentContentIndex: number;
  onContentChange: (index: number) => void;
}

export const BundleSidebar = ({
  bundle,
  currentContentIndex,
  onContentChange,
}: BundleSidebarProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const totalItems = bundle.content.length;

      switch (event.key) {
        case 'ArrowUp': {
          event.preventDefault();
          const prevIndex = (currentContentIndex - 1 + totalItems) % totalItems;
          onContentChange(prevIndex);
          break;
        }
        case 'ArrowDown': {
          event.preventDefault();
          const nextIndex = (currentContentIndex + 1) % totalItems;
          onContentChange(nextIndex);
          break;
        }
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [bundle.content.length, currentContentIndex, onContentChange]);

  return (
    <Box
      bgColor='neutral.0'
      borderRight='1px solid'
      borderColor='neutral.300'
      py={40}
      px={26}
      boxSizing='border-box'
      width='280px'
      boxShadow='4px 0px 15px 0px rgba(174, 174, 174, 0.25)'
      overflow='auto'
      zIndex={1}
    >
      <List spacing={8} p={0} m={0}>
        {bundle.content.map((item, index) => (
          <ListItem
            key={item.id}
            cursor='pointer'
            p={8}
            borderRadius={6}
            bgColor={currentContentIndex === index ? 'primary.50' : 'transparent'}
            onClick={() => onContentChange(index)}
            _hover={{bgColor: 'neutral.100'}}
            tabIndex={0}
            role='option'
            aria-selected={currentContentIndex === index}
          >
            <Flex gap={2}>
              <ListIcon
                marginTop='3px'
                as={
                  item.content_type
                    ? ICONS[getAssetExtension(item.content_type)]
                    : RiFileTextLine
                }
                color={currentContentIndex === index ? 'primary.500' : 'neutral.500'}
              />
              <Text
                fontSize='sm'
                color={currentContentIndex === index ? 'primary.500' : 'neutral.700'}
              >
                {item.label}
              </Text>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
