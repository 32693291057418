import {Flex, Box, Skeleton, SkeletonText, Divider} from 'spekit-ui';

interface PublicContentSkeletonProps {
  isDealRoom?: boolean;
}

export const PublicContentSkeleton = ({
  isDealRoom = false,
}: PublicContentSkeletonProps) => {
  return (
    <Flex
      direction='column'
      h='100vh'
      bgColor='neutral.25'
      data-testid='public-content-skeleton'
    >
      {/* Header Skeleton */}
      <Flex borderBottom='1px solid' borderBottomColor='neutral.200'>
        <Flex
          m={20}
          p={14}
          borderRadius={6}
          alignItems='center'
          bgColor='neutral.0'
          width='100%'
          justifyContent='space-between'
        >
          <Flex width='100%'>
            <Skeleton w='20%' height={'24px'} borderRadius={4} />
            <Divider orientation='vertical' m={0} mx={16} h='auto' alignSelf='stretch' />
            <Skeleton w='20%' height={'24px'} borderRadius={4} />
          </Flex>
          <Flex gap={12} ml='auto'>
            <Skeleton w={'100px'} height={'36px'} borderRadius={4} />
          </Flex>
        </Flex>
      </Flex>

      {/* Content with optional Sidebar */}
      <Flex
        flexGrow={1}
        bgColor='neutral.25'
        alignItems='stretch'
        justifyContent='center'
      >
        {isDealRoom && (
          <Box
            w='280px'
            borderRight='1px solid'
            borderRightColor='neutral.200'
            bgColor='neutral.0'
            p={'30px'}
            boxSizing='border-box'
            data-testid='deal-room-sidebar-skeleton'
          >
            <Flex direction='column'>
              {[1, 2, 3].map((index) => (
                <Flex key={index} p={3} borderRadius={4} alignItems='center' gap={3}>
                  <Skeleton w='24px' h='24px' borderRadius={4} />
                  <Skeleton w='80%' h='20px' borderRadius={4} />
                </Flex>
              ))}
            </Flex>
          </Box>
        )}

        {/* Main Content Skeleton */}
        <Flex flexGrow={1} justifyContent='center' overflow='hidden' bgColor='neutral.0'>
          <Box
            p={40}
            my={22}
            w='100%'
            mx={46}
            maxW='816px'
            bgColor='neutral.0'
            border='1px solid'
            borderColor='neutral.300'
          >
            <Box>
              <Skeleton w='40%' height={'32px'} borderRadius={4} mb={24} />
              <SkeletonText w='100%' height={'200px'} noOfLines={10} />
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
