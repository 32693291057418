export const QUERY_KEYS = {
  CustomFields: 'custom_fields',
  Seismic: 'seismic',
  Spek: 'spek',
  Summaries: 'context_summaries',
  RecentlyViewedContent: 'recent_views',
  BrandConfig: 'brand_config',
  FeatureFlags: 'feature_flags',
  StarredTerms: 'starred_terms',
  FeedbackQuestions: 'feedback_questions',
  ListContentFiles: 'list_content_files',
  SharePointSearch: 'sharepoint_search',
  ConfluenceSearch: 'confluence_search',
  TopicDetails: 'topic_details',
  DealRoom: 'deal_room',
  PublicContent: 'public_content',
  DealRoomsAnalytics: 'deal_rooms_analytics',
  DealRoomAnalytics: 'deal_room_analytics',
  DealRoomContentActivity: 'deal_room_content_activity',
} as const;
