import {
  ICreateShareableContentEventRequest,
  IGenerateShareLinkRequest,
  IGenerateShareLinkResponse,
  IPublicContent,
  IPublicBundle,
  IPublicContentError,
  IShareableCompanyResponse,
  IShareableCompanyListResponse,
  IShareLinkAnalyticsResponse,
  IShareLinkSearchQuery,
  IShareContentActivityResponse,
  ICreateShareableContentActivity,
  IGenerateExternalLinkRequest,
  IShareLinkCompanyAnalyticsResponse,
  IShareLinkRecentActivitysResponse,
  IShareLinkContentListResponse,
  IShareLinkContentDetailResponse,
  IShareLinkPagination,
  IShareLinkCompanySearchQuery,
  IShareLinkCompanyResponse,
  IDealRoomListResponse,
} from 'spekit-types';

import {
  SHARABLE_CONTENT_ACTIVITY,
  EXTERNAL_LINK_API,
  SHARE_LINK_API,
  SHAREABLE_CONTENT_EVENT_API,
  SHAREABLE_COMPANY_API,
  COMPANY_SHAREABLE_ANALYTICS_API,
  BUNDLE_ANALYTICS_API,
  api,
  DEAL_ROOM_ANALYTICS_API,
} from '../api';

import {retrieve, create, update} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';

export const getShareLinkAnalytics = (searchQuery: IShareLinkSearchQuery) => {
  const query = stringifyQs(searchQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkAnalyticsResponse>(`${SHARE_LINK_API}${query}`);
};

export const generateShareLink = async (payload: IGenerateShareLinkRequest) => {
  const response: IGenerateShareLinkResponse = await create(`${SHARE_LINK_API}`, payload);
  return response;
};

export const getExternalLink = async (payload: IGenerateExternalLinkRequest) => {
  const response: IGenerateShareLinkResponse = await create(
    `${EXTERNAL_LINK_API}`,
    payload
  );
  return response;
};

export const createShareableContentEvent = async (
  payload: ICreateShareableContentEventRequest
) => {
  return await create(`${SHAREABLE_CONTENT_EVENT_API}`, payload);
};

export const createShareableContentActivity = async (
  payload: ICreateShareableContentActivity
) => {
  return await create(`${SHARABLE_CONTENT_ACTIVITY}`, payload);
};

export const getShareLinkActivity = (id: string) => {
  return retrieve<IShareContentActivityResponse>(`${SHARABLE_CONTENT_ACTIVITY}${id}`);
};

export const getPublicContent = (
  contentType: string,
  contentId: string
): Promise<IPublicContent | IPublicBundle | IPublicContentError> => {
  if (!contentId) {
    throw new Error('Content ID is required');
  }

  const getContentUrl = (type: string, id: string): string => {
    switch (type) {
      case 'content':
        return `/share/v1/${type}/${id}/`;
      case 'deal-room':
        return `/share/v1/public-bundle/${id}/`;
      default:
        return `/public/${type}s/${id}/`;
    }
  };

  const requestUrl = getContentUrl(contentType, contentId);
  return api.get(requestUrl).then(({data}) => data);
};

export const getCompanyNames = (searchTerm?: string, emails?: string[]) => {
  const query = stringifyQs({q: searchTerm, emails});
  return retrieve<IShareableCompanyListResponse>(`${SHAREABLE_COMPANY_API}?${query}`);
};

export const getDealRoomNames = () =>
  retrieve<IDealRoomListResponse>(`${SHARE_LINK_API}`);

export const createCompany = async (name: string) => {
  const response: IShareableCompanyResponse = await create(`${SHAREABLE_COMPANY_API}`, {
    name,
  });
  return response;
};

export const getShareLinksByCompany = (
  searchQuery: IShareLinkCompanySearchQuery,
  hasSmartSendROIFlag: boolean
) => {
  const query = stringifyQs(searchQuery, {
    addQueryPrefix: true,
  });

  const url = hasSmartSendROIFlag
    ? BUNDLE_ANALYTICS_API
    : COMPANY_SHAREABLE_ANALYTICS_API;

  return retrieve<IShareLinkCompanyAnalyticsResponse>(`${url}${query}`);
};

export const getRecentActivity = (
  companyId: string,
  paginationQuery: IShareLinkPagination,
  hasSmartSendROIFlag?: boolean,
  isDealRoom?: boolean
) => {
  const url = isDealRoom
    ? DEAL_ROOM_ANALYTICS_API
    : hasSmartSendROIFlag
    ? BUNDLE_ANALYTICS_API
    : COMPANY_SHAREABLE_ANALYTICS_API;

  const query = stringifyQs(paginationQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkRecentActivitysResponse>(
    `${url}${companyId}/recent_activity/${query}`
  );
};

export const getShareLinkList = (
  companyId: string,
  paginationQuery: IShareLinkPagination,
  hasSmartSendROIFlag?: boolean
) => {
  const url = hasSmartSendROIFlag
    ? BUNDLE_ANALYTICS_API
    : COMPANY_SHAREABLE_ANALYTICS_API;
  const query = stringifyQs(paginationQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkContentListResponse>(
    `${url}${companyId}/share_links/${query}`
  );
};

export const getShareLinkDetail = (
  contentId: string,
  paginationQuery: IShareLinkPagination,
  hasSmartSendROIFlag?: boolean
) => {
  const url = hasSmartSendROIFlag
    ? BUNDLE_ANALYTICS_API
    : COMPANY_SHAREABLE_ANALYTICS_API;
  const query = stringifyQs(paginationQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkContentDetailResponse>(
    `${url}${contentId}/share_link_detail/${query}`
  );
};

export const getCompanyDetails = (companyId: string, hasSmartSendROIFlag: boolean) => {
  const url = hasSmartSendROIFlag
    ? BUNDLE_ANALYTICS_API
    : COMPANY_SHAREABLE_ANALYTICS_API;
  return retrieve<IShareLinkCompanyResponse>(`${url}${companyId}`);
};

export const assignCompanyToShareLink = (
  bundleId: string,
  companyId: string,
  entityType: string
) => {
  return update(`${SHARE_LINK_API}`, {
    bundle_id: bundleId,
    company_id: companyId,
    entity_type: entityType,
  });
};
