import {useCallback, useEffect, useState} from 'react';
import {termView} from 'spekit-datalayer';
import {track} from '../utils/analytics';
import {IPublicContent, IPublicBundle} from 'spekit-types';
import {
  DSButton as Button,
  Flex,
  Text,
  Icon,
  Box,
  useToast,
  DSTooltip as Tooltip,
  Heading,
  Divider,
  Link,
  PDFViewer,
} from 'spekit-ui';
import ErrorView from './ErrorView';
import {useParams} from 'react-router-dom';
import {RiDownloadLine, RiShareForwardLine} from 'react-icons/ri';
import ContentTimeTracker from '../ContentTimeTracker/ContentTimeTracker';
import {EmailAddressModal} from './EmailAddressModal';
import {setItem, getItem} from 'spekit-ui/src/utils/localStorage';
import {ReadOnlyView} from 'spekit-shared-components/src/RichTextEditor/components';
import {BundleSidebar} from './BundleSidebar';
import {usePublicContentData} from './usePublicContentData';
import {PublicContentSkeleton} from './PublicContentSkeleton';

export const PublicContent = () => {
  const {contentType, contentId} = useParams<{contentType: string; contentId: string}>();
  const {
    content,
    error,
    bundle,
    isPrivate,
    isLoading,
    currentContentIndex,
    handleContentChange,
  } = usePublicContentData({
    contentType,
    contentId,
  });

  const emailAddressStorageKey = 'smart_send_user_email';
  const [termTimeViewedAt, setTermTimeViewedAt] = useState(0);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(true);
  const [userEmailAddress, setUserEmailAddress] = useState('');
  const [isTimeTrackingEnabled, setIsTimeTrackingEnabled] = useState(false);
  const toast = useToast();

  const logTermView = useCallback(() => {
    if (content) {
      let dataPayload = {
        term_id: content.id,
        term_type: content.type,
        term_label: content.label,
        source: 'Webapp',
        time_spent: termView.getTotalTimeSpent(termTimeViewedAt),
        screen_name: 'Public Spek View',
      };
      track('Spek Viewed', dataPayload);
    }
  }, [content, termTimeViewedAt]);

  const handleEmailAndTrackingInitialVisibility = useCallback(
    (response: IPublicContent | IPublicBundle) => {
      const emailAddress = getItem(emailAddressStorageKey);
      const {
        is_internal_user: isInternalUser = false,
        is_email_required: isEmailRequired = false,
      } = response;

      if (!isEmailRequired || isInternalUser || !!emailAddress) {
        setIsEmailModalOpen(false);

        if (!!emailAddress) {
          setUserEmailAddress(emailAddress);
        }

        setIsTimeTrackingEnabled(!isInternalUser);
      }
    },
    []
  );

  useEffect(() => {
    if (content) {
      handleEmailAndTrackingInitialVisibility(content);
      setTermTimeViewedAt(new Date().getTime());
    }

    return () => {
      if (content) {
        logTermView();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, handleEmailAndTrackingInitialVisibility]);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast({
        variant: 'success',
        description: 'Link copied to clipboard',
      });
    } catch (e) {
      toast({
        variant: 'error',
        description: `Couldn't copy to clipboard`,
      });
    }
  };

  const handleAssetDownload = () => {
    if (content) {
      // Track download event
      const trackingPayload = {
        asset_id: content.id,
        asset_label: content.label,
        content_type: content.type,
        source: 'Webapp',
        screen_name: 'Public Spek View',
      };
      track('SmartSend Asset Downloaded', trackingPayload);

      // Open the download URL in a new tab for download
      window.open(content.download_url, '_blank');
    }
  };

  const handleOnContinueEmailModal = (emailAddress: string) => {
    setItem(emailAddressStorageKey, emailAddress);
    setUserEmailAddress(emailAddress);
    setIsEmailModalOpen(false);
    setIsTimeTrackingEnabled(true);
  };

  const renderSpek = () => {
    if (!!content?.definition) {
      if (isTimeTrackingEnabled) {
        return <ContentTimeTracker content={content} email={userEmailAddress} />;
      } else {
        return <ReadOnlyView value={content.definition} />;
      }
    }

    return <Text variant='body2'>Spek is not defined.</Text>;
  };

  const renderFile = () => {
    if (content) {
      return isTimeTrackingEnabled ? (
        <ContentTimeTracker content={content} email={userEmailAddress} />
      ) : (
        <PDFViewer contentId={content.id} url={content.url || content.definition!} />
      );
    }
    return null;
  };

  const isDealRoom = contentType === 'deal-room';
  const sentFrom = isDealRoom ? bundle?.sent_from : content?.sent_from;

  if (isLoading) {
    return <PublicContentSkeleton isDealRoom={isDealRoom} />;
  }

  if (error) {
    return <ErrorView isPrivate={isPrivate} error={error} />;
  }

  return content ? (
    <Flex direction='column' h='100vh' bgColor='neutral.25'>
      <Flex
        p={14}
        m={20}
        borderRadius={6}
        rowGap={6}
        alignItems='center'
        bgColor='neutral.0'
        justifyContent='space-between'
        flexWrap={'wrap'}
        borderColor='neutral.200'
        borderWidth='1px'
        boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1)'
      >
        <Flex alignItems='center' overflow='hidden' flexGrow={1}>
          <Tooltip label={content.shareable_bundle_name}>
            <Text
              maxW='80%'
              overflow='hidden'
              whiteSpace='nowrap'
              fontWeight='semibold'
              textOverflow='ellipsis'
              data-testid='shared-file-name'
            >
              {bundle ? bundle.name : content.shareable_bundle_name}
            </Text>
          </Tooltip>

          {!content.is_default && (
            <>
              <Divider
                orientation='vertical'
                m={0}
                mx={16}
                h='auto'
                alignSelf='stretch'
              />
              <Text variant='body2' data-testid='sent-from-email'>
                Sent from <Link href={`mailto:${sentFrom}`}>{sentFrom}</Link>
              </Text>
            </>
          )}
        </Flex>
        <Flex gap={12}>
          {(contentType === 'asset' || content.type === 'asset') &&
            content.download_url &&
            content.allow_download &&
            !isDealRoom && (
              <Button
                leftIcon={<Icon as={RiDownloadLine} h={4} w={4} />}
                size='medium'
                variant='outlined'
                colorScheme='primary'
                onClick={handleAssetDownload}
                mr={2}
                data-testid='download-asset'
                _hover={{
                  bg: 'primary.50',
                  borderColor: 'primary.600',
                }}
              >
                Download
              </Button>
            )}
          <Tooltip label='Copy share link'>
            <Button
              size='medium'
              variant='contained'
              colorScheme='primary'
              onClick={handleCopyLink}
              leftIcon={<Icon as={RiShareForwardLine} h={4} w={4} />}
              data-testid='copy-share-link'
              aria-label='Copy share link'
            >
              Share
            </Button>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex
        flexGrow={1}
        bgColor='neutral.50'
        alignItems='stretch'
        justifyContent='center'
        overflow='hidden'
        position='relative'
        borderTop='1px solid'
        borderTopColor='neutral.200'
      >
        {bundle && (
          <BundleSidebar
            bundle={bundle}
            currentContentIndex={currentContentIndex}
            onContentChange={handleContentChange}
          />
        )}
        <Flex flexGrow={1} justifyContent='center' overflow='hidden' bgColor='neutral.0'>
          {contentType === 'asset' || content.type === 'asset' ? (
            <Box
              w='100%'
              h='100%'
              overflow='hidden'
              display='flex'
              flexDirection='column'
              boxShadow='0 2px 4px rgba(0, 0, 0, 0.05)'
            >
              {isDealRoom && (
                <Box px={40} py={24}>
                  <Flex justifyContent='space-between' alignItems='center'>
                    <Heading as='h4' fontWeight='semibold'>
                      {content.label}
                    </Heading>
                    {content.type === 'asset' &&
                      content.download_url &&
                      content.allow_download && (
                        <Button
                          leftIcon={<Icon as={RiDownloadLine} h={4} w={4} />}
                          size='medium'
                          variant='outlined'
                          colorScheme='primary'
                          onClick={handleAssetDownload}
                          mr={2}
                          data-testid='download-asset'
                        >
                          Download
                        </Button>
                      )}
                  </Flex>
                </Box>
              )}
              <Box
                flexGrow={1}
                overflow='hidden'
                position='relative'
                px={isDealRoom ? 40 : 0}
                pb={isDealRoom ? 40 : 0}
              >
                {renderFile()}
              </Box>
            </Box>
          ) : (
            <Box
              p={40}
              my={22}
              w='100%'
              mx={46}
              maxW='816px'
              bgColor='neutral.0'
              border='1px solid'
              borderColor='neutral.200'
              overflow='auto'
              maxH='calc(100vh - 180px)'
              boxShadow='0 2px 8px rgba(0, 0, 0, 0.05)'
            >
              <Box>
                <Heading as='h4' fontWeight='semibold' mb={24}>
                  {content.label}
                </Heading>
                {renderSpek()}
              </Box>
            </Box>
          )}
        </Flex>
        <EmailAddressModal
          isOpen={isEmailModalOpen}
          onViewContent={handleOnContinueEmailModal}
          onClose={() => {
            setIsEmailModalOpen(false);
          }}
        ></EmailAddressModal>
      </Flex>
    </Flex>
  ) : (
    <ErrorView isPrivate={isPrivate} error={error} />
  );
};
