import React from 'react';
import {forwardRef, Menu as ChakraMenu, MenuProps} from '@chakra-ui/react';

export type TMenuProps = MenuProps;

export const MenuConfig = {
  baseStyle: {},
  parts: ['menu', 'item', 'list'],
  variants: {
    default: {
      list: {
        minW: '200px',
        maxW: '408px',
        bg: 'neutral.0',
        boxShadow: 'md',
        border: '1px solid',
        borderColor: 'neutral.200',
        borderRadius: 8,
        py: 6,
      },
      item: {
        cursor: 'pointer',
        bg: 'neutral.0',
        _hover: {
          bg: 'neutral.25',
        },
        _focus: {
          bg: 'neutral.25',
        },
        border: '0px',
        px: 20,
        py: 10,
      },
    },
    outlook: {
      list: {
        minW: '200px',
        maxW: '290px',
        bg: 'neutral.0',
        boxShadow: 'md',
        border: '1px solid',
        borderColor: 'neutral.200',
        borderRadius: 8,
        py: 6,
      },
      item: {
        cursor: 'pointer',
        bg: 'neutral.0',
        _hover: {
          bg: 'neutral.25',
        },
        _focus: {
          bg: 'neutral.25',
        },
        border: '0px',
        px: 20,
        py: 10,
      },
    },
  },
  sizes: {},
  defaultProps: {
    variant: 'default',
  },
};

export const Menu = (props: TMenuProps) => <ChakraMenu {...props} direction='rtl' />;
