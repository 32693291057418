import {useQuery} from '@tanstack/react-query';
import {shareLinks, QUERY_KEYS} from 'spekit-datalayer';
import {IPublicContent, IPublicBundle} from 'spekit-types';
import {useState, useCallback} from 'react';
import {AxiosError} from 'axios';

interface IUsePublicContentDataParams {
  contentType: string;
  contentId: string;
}

interface IUsePublicContentDataResult {
  content?: IPublicContent;
  bundle?: IPublicBundle;
  isPrivate: boolean;
  error: string | null;
  isLoading: boolean;
  currentContentIndex: number;
  handleContentChange: (index: number) => void;
}

interface ErrorResponse {
  message: string;
}

const createBaseState = (
  handleContentChange: (index: number) => void
): Omit<IUsePublicContentDataResult, 'content' | 'bundle'> => ({
  isPrivate: false,
  error: null,
  isLoading: false,
  currentContentIndex: 0,
  handleContentChange,
});

export const usePublicContentData = ({
  contentType,
  contentId,
}: IUsePublicContentDataParams): IUsePublicContentDataResult => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  const {data, isLoading, error} = useQuery({
    queryKey: [QUERY_KEYS.PublicContent, contentType, contentId],
    queryFn: () => shareLinks.getPublicContent(contentType, contentId),
    enabled: !!contentType && !!contentId,
  });

  const handleContentChange = useCallback(
    (index: number) => {
      if (data && 'content' in data && index >= 0 && index < data.content.length) {
        setCurrentContentIndex(index);
      }
    },
    [data]
  );

  if (isLoading) {
    return {
      ...createBaseState(handleContentChange),
      isLoading: true,
    };
  }

  if (error || data?.success === false) {
    return {
      ...createBaseState(handleContentChange),
      isPrivate: data && 'private' in data ? data.private : false,
      error:
        (error as AxiosError<ErrorResponse>)?.response?.data?.message ??
        'An error occurred',
    };
  }

  if (!data) {
    return createBaseState(handleContentChange);
  }

  if ('content' in data) {
    return {
      ...createBaseState(handleContentChange),
      content: data.content[currentContentIndex],
      bundle: data as IPublicBundle,
      currentContentIndex,
    };
  }

  return {
    ...createBaseState(handleContentChange),
    content: data as IPublicContent,
  };
};
